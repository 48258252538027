import HttpRequest from './core/http_request';

class UploadfileProvider extends HttpRequest {
  getStatus () {
    return this.request('get', '/uploadFile/status');
  }

  uploadFile (data) {
    return this.request('upload', '/uploadFile/uploadSourceFile', data);
  }
}

export default UploadfileProvider;
