import HttpRequest from './core/http_request';

class ReportProvider extends HttpRequest {
  getIncidentType (data) {
    return this.request('get', '/report/incidenttype', data);
  }

  getByProvince (data) {
    const id = data.id;
    delete data._id;
    return this.request('get', '/report/byAmphur/' + id, data);
  }

  getCountViolenceType (data) {
    return this.request('get', '/report/countviolencetype', data);
  }

  getCountByActorType (data) {
    return this.request('get', '/report/countbyactortype', data);
  }

  getCountIncidentsByActorType (data) {
    return this.request('get', '/report/countactorbyincident', data);
  }

  getCountIncidentsByProvince (data) {
    return this.request('get', '/report/countincidentsbyprovince', data);
  }
}

export default ReportProvider;
